// AdminDashboard.js
import React from 'react';
import Layout from './Layout';
import useLogin from './hooks/useLogin';
const Accounts = (props) => {
  const users = props.accounts || []; // Ensure users array is available
  const { signOut } = useLogin();

  console.log(users);
  return (
    <Layout>
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-4">Accounts</h2>
        <div className="bg-white p-4 rounded shadow">
          {users.length > 0 ? (
            <table className="min-w-full table-auto border-collapse border border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 border border-gray-300 text-left">ID</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Name</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Email</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Users</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Groups</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Certificates</th>
                  <th className="px-4 py-2 border border-gray-300 text-left">Recipients</th>


                  <th className="px-4 py-2 border border-gray-300 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id} className="hover:bg-gray-50">
                    <td className="px-4 py-2 border border-gray-300">{user.id}</td>
                    <td className="px-4 py-2 border border-gray-300">{user.name}</td>
                    <td className="px-4 py-2 border border-gray-300">{user.email}</td>
                    <td className="px-4 py-2 border border-gray-300">{user.users_count}</td>
                    <td className="px-4 py-2 border border-gray-300">{user.groups_count}</td>
                    <td className="px-4 py-2 border border-gray-300">{user.certificates_count}</td>
                    <td className="px-4 py-2 border border-gray-300">{user.group_recipients_count}</td>

                    <td className="px-4 py-2 border border-gray-300">
                      <button className="text-blue-600 hover:underline">Edit</button>
                      <button className="text-red-600 hover:underline ml-4">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-gray-500">No users available.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Accounts;
